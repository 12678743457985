import { NavLink } from "react-router-dom";
import { FaCode } from "react-icons/fa";
import DevProjects from "./DevProjects";
import { AiOutlineArrowLeft } from "react-icons/ai";

function WebApps() {
  return (
    <>
      <p className="p_description">
        <FaCode /> Fun web apps and projects <FaCode />
      </p>
      <div className="img_container">
        <div className="decoration_words">
          <DevProjects
            className="item item_1"
            projectLink="https://no-state-node-delta.vercel.app/Welcome"
            projectName="#NoStateNode"
          />
          <DevProjects
            className="item item_2"
            projectLink="https://charlybgood.github.io/webAppsJS/musicPlayerApp/index.html"
            projectName="#musicPlayer"
          />
          <DevProjects
            className="item item_3"
            projectLink="https://charlybgood.github.io/makeYourQR/"
            projectName="#qrMaker"
          />
          <DevProjects
            className="item item_1"
            projectLink="https://charlybgood.github.io/paintitblack/"
            projectName="#paintItBlack"
          />
          <DevProjects
            className="item item_2"
            projectLink="https://classic-todo-app.vercel.app/"
            projectName="#ToDoList"
          />
          <DevProjects
            className="item item_3"
            projectLink="https://charlybgood.github.io/webAppsJS/photoEffectApp/index.html"
            projectName="#photoEffect"
          />
        </div>
      </div>
      <button className="btn-back">
        <NavLink to="/DevHome">
          <AiOutlineArrowLeft />
        </NavLink>
      </button>
    </>
  );
}

export default WebApps;
