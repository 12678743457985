import "./App.css";
import { Route, Routes } from "react-router-dom";
import Content from "./components/Content";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Music from "./components/Music";
import VisualArt from "./components/VisualArt";
import DevHome from "./components/DevPortfolio";
import WebApps from "./components/WebApps";
import PageNotFound from "./components/PageNotFound";
import WebPages from "./components/WebPages";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Content />} />
        <Route path="/Music" element={<Music />} />
        <Route path="/Music/:songId" element={<Music />} />
        <Route path="/VisualArt" element={<VisualArt />} />
        <Route path="/VisualArt/:imageId" element={<VisualArt />} />
        <Route path="/DevHome" element={<DevHome />} />
        <Route path="/DevHome/WebPages" element={<WebPages />} />
        <Route path="/DevHome/WebApps" element={<WebApps />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
