import "../stylesheets/Music.css";
import { useRef, useState, useCallback, lazy, Suspense } from "react";
import songs from "../data/songs_db";
import { FaArrowCircleDown } from "react-icons/fa";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { NavLink } from "react-router-dom";

const SongList = lazy(() => import("./music_player/SongList"));
const PlayerControls = lazy(() => import("./player_controls/PlayerControls"));
const VolumeControls = lazy(() => import("./player_controls/VolumeControls"));

function Music() {
  const player = useRef(null);
  const playerMain = useRef(null);
  const songList = useRef(null);
  const volSlider = useRef(null);
  const progressBar = useRef(null);
  const [currentSong, setCurrentSong] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [volOn, setVolOn] = useState(true);
  const [timeUpdate, setTimeUpdate] = useState();
  const [timeDuration, setTimeDuration] = useState();

  const selectThisSong = useCallback((song) => {
    setIsPlaying(true);
    setCurrentSong(song);
    player.current.src = require(`../music/${song}.mp3`);
    player.current.play();
    window.scrollTo({ top: playerMain.current.offsetTop, behavior: "smooth" });
  }, []);

  const playPause = useCallback(() => {
    if (!isPlaying) {
      setIsPlaying(true);
      if (currentSong) {
        player.current.play();
      } else {
        selectThisSong(songs[0]);
      }
    } else {
      setIsPlaying(false);
      player.current.pause();
    }
  }, [isPlaying, currentSong, selectThisSong]);

  const stopSong = useCallback(() => {
    player.current.pause();
    player.current.currentTime = 0;
    setIsPlaying(false);
    progressBar.current.value = "0";
    setCurrentSong("");
  }, []);

  const prevSong = useCallback(() => {
    const currentIndex = songs.indexOf(currentSong);
    let prevIndex = currentIndex - 1;
    if (prevIndex < 0) {
      prevIndex = songs.length - 1;
    }
    const prevTrack = songs[prevIndex];
    setCurrentSong(prevTrack);
    player.current.src = require(`../music/${prevTrack}.mp3`);
    setIsPlaying(true);
    player.current.play();
  }, [currentSong]);

  const nextSong = useCallback(() => {
    const currentIndex = songs.indexOf(currentSong);
    const nextIndex = (currentIndex + 1) % songs.length;
    const nextTrack = songs[nextIndex];
    setCurrentSong(nextTrack);
    player.current.src = require(`../music/${nextTrack}.mp3`);
    setIsPlaying(true);
    player.current.play();
  }, [currentSong]);

  const scroll = useCallback(() => {
    window.scrollTo({ top: songList.current.offsetTop, behavior: "smooth" });
  }, []);

  const maxMinVol = useCallback(() => {
    player.current.volume = volSlider.current.value / 100;
  }, []);

  const muteVol = useCallback(() => {
    player.current.volume = 0;
    setVolOn(false);
  }, []);

  const unMuteVol = useCallback(() => {
    player.current.volume = 1;
    setVolOn(true);
  }, []);

  const calcTime = useCallback((secs) => {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const retSecs = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${retSecs}`;
  }, []);

  const clickProgressBar = useCallback((e) => {
    const newTime =
      (e.nativeEvent.offsetX / progressBar.current.offsetWidth) *
      player.current.duration;
    player.current.currentTime = newTime;
  }, []);

  const updateTime = useCallback(() => {
    setTimeUpdate(calcTime(player.current.currentTime));
    if (player.current.readyState > 0) {
      setTimeDuration(calcTime(player.current.duration));
    }
  }, [calcTime]);

  const handleProgressChange = useCallback(() => {
    const duration = player.current.duration;
    const currentTime = player.current.currentTime;
    if (currentTime === 0) {
      progressBar.current.value = currentTime;
    } else {
      progressBar.current.value = (currentTime / duration) * 100;
    }
    updateTime();
  }, [updateTime]);

  

  return (
    <>
      <div className="player" id="player_main" ref={playerMain}>
        <h3 className="gallery-title">m Ú § i ç ♫</h3>
        <div className="player_one">
          <div className="radio">
            <i className="music_author">
              Music by
              <a
                href="https://soundcloud.com/charly-bgood"
                target="_blank"
                rel="noopener noreferrer"
              >
                Charly BGood
              </a>
            </i>
            <div
              className={currentSong ? "player_tv tv_on" : "player_tv tv_off"}
            ></div>
            <audio
              id="player"
              ref={player}
              onEnded={nextSong}
              onTimeUpdate={handleProgressChange}
            ></audio>
            <div id="controls">
              <p>{currentSong ? `#${currentSong}` : ""}</p>
              <Suspense fallback={<div>Loading...</div>}>
                <PlayerControls
                  playPause={playPause}
                  stopSong={stopSong}
                  prevSong={prevSong}
                  nextSong={nextSong}
                  isPlaying={isPlaying}
                />
                <div className="progress_section">
                  <span className="durStart">
                    {currentSong ? timeUpdate : "-"}
                  </span>
                  <progress
                    max="100"
                    id="progress-bar"
                    ref={progressBar}
                    className="progress"
                    onPointerDown={clickProgressBar}
                  ></progress>
                  <span className="durEnd">
                    {currentSong ? timeDuration : "-"}
                  </span>
                </div>
                <div className="volume">
                  <VolumeControls
                    maxMinVol={maxMinVol}
                    muteVol={muteVol}
                    unMuteVol={unMuteVol}
                    volSlider={volSlider}
                    volOn={volOn}
                  />
                </div>
              </Suspense>
              <button className="btn-down" onClick={scroll}>
                <FaArrowCircleDown />
              </button>
            </div>
          </div>
          <div id="songList" ref={songList}>
            <Suspense fallback={<div>Loading...</div>}>
              <SongList selectThisSong={selectThisSong} />
            </Suspense>
          </div>
        </div>
      </div>
      <button className="btn-back">
        <NavLink to="/">
          <AiOutlineArrowLeft />
        </NavLink>
      </button>
    </>
  );
}

export default Music;