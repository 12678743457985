import "../stylesheets/Content.css";
import { NavLink } from "react-router-dom";
import ImgCenter from "../img/img-planetavacio.png";
import { FaFolderOpen, FaCode, FaArchive } from "react-icons/fa";
import { AiOutlineArrowLeft } from "react-icons/ai";

function DevPortfolio() {
  return (
    <>
      <div className="img_container">
        <>
          <h3 className="gallery-title">
            _/dev_Home_
            <FaCode />
          </h3>
        </>
        <div className="decoration_words">
          <NavLink className="item item_1" to="./WebApps">
            <FaFolderOpen />
            _web_Apps
            <FaArchive />
          </NavLink>
          <img className="img" src={ImgCenter} alt="Charly BGood Logo" />
          <NavLink className="item item_2" to="./WebPages">
            <FaFolderOpen />
            _web_Pages
            <FaCode />
          </NavLink>
        </div>
      </div>
      <button className="btn-back">
        <NavLink to="/">
          <AiOutlineArrowLeft />
        </NavLink>
      </button>
    </>
  );
}

export default DevPortfolio;
